import React from "react";
import heroLogo from "../img/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const ComingSoon = () => (
  <div className="bg">
    <div className="container">
      <div className="hero">
        <img src={heroLogo} alt="" className="hero__logo" />
        <h1 className="hero__heading">Coming Soon!</h1>
      </div>

      <div className="cs-contact">
        <div className="cs-contact__list">
          <a href="https://www.facebook.com/sales.ibssmith">
            <div className="cs-contact__item  cs-contact__facebook">
              <FontAwesomeIcon icon={faFacebookF} />
              <p>IBS Smith</p>
            </div>
          </a>
          <div className="cs-contact__item cs-contact__phone">
            <FontAwesomeIcon icon={faPhoneAlt} />
            <p>081-171-1723</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ComingSoon;
