import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
        <Header/>


        <Footer/>
    </div>
  );
}

export default App;
